<template>
  <div class="MonitorActions-toolbar">
    <div class="MonitorActions">
      <div ref="trigger">
        <base-button
          class="button"
          color="primary"
          icon="IconButtonArrowDown"
          :icon-width="12"
          :icon-height="7"
        >
          Bulk Actions
        </base-button>
      </div>

      <div ref="dropdown">
        <nav class="nav">
          <a href="#" class="nav-item" v-if="user.role !== role.Viewer && user.role !== role.Accountant"
             @click.prevent="openTagsModal">Add tags</a>
          <a href="#" class="nav-item" @click.prevent="pause">Pause</a>
          <a href="#" class="nav-item" @click.prevent="run">Start</a>
          <a href="#" class="nav-item" @click.prevent="deleteMonitors">Delete</a>
          <a href="#" class="nav-item" @click.prevent="resetMonitors">Reset</a>
        </nav>
      </div>
    </div>
  </div>
</template>

<script>
import tippy from 'tippy.js'
import monitorsApi from '@/api/monitorsApi.js'
import swal from 'sweetalert2'
import { Role } from '@/_helpers/role'

export default {
  props: {
    selectedMonitors: {
      required: true,
      type: Array
    }
  },

  data () {
    return {
      tippyInstance: null,
      open: false,
      action: null,
      role: Role
    }
  },

  mounted () {
    this.initDropdown()
  },

  methods: {
    initDropdown () {
      this.tippyInstance = tippy(this.$refs.trigger, {
        content: this.$refs.dropdown,
        interactive: true,
        theme: 'light-border',
        animation: 'shift-away-subtle',
        placement: 'top-end',
        arrow: true,
        inertia: true,
        trigger: 'click',
        maxWidth: 'none',

        onShow: () => {
          this.open = true
        },

        onHide: () => {
          this.open = false
        }
      })
    },
    async pause () {
      this.tippyInstance.hide()

      await monitorsApi.multiplePause(this.selectedMonitors.map(item => item.id))

      this.$emit('pause')
    },

    async run () {
      if (this.user.subscription_paused) {
        return false
      }

      this.tippyInstance.hide()

      await monitorsApi.multipleRun(this.selectedMonitors.map(item => item.id))

      this.$emit('run')
    },

    async deleteMonitors () {
      swal.fire({
        html: '<h4>' + 'Are you sure that you want to delete the selected monitors?</h4>',
        title: '',
        showClass: {
          popup: ''
        },
        hideClass: {
          popup: ''
        },
        showCancelButton: true,
        confirmButtonColor: '#eb5757',
        cancelButtonColor: '#9ca3a1',
        confirmButtonText: 'Delete',
        cancelButtonText: 'Cancel'
      }).then(async (val) => {
        if (val.value === true) {
          this.tippyInstance.hide()
          try {
            await monitorsApi.multipleDelete(this.selectedMonitors.map(item => item.id))
            this.$emit('delete')
            this.action = 'deleted'
            this.showSuccessMessage()
          } catch (e) {
            this.showErrorMessage()
          }
        }
      })
    },
    async resetMonitors () {
      swal.fire({
        html: '<h4>' + 'Are you sure that you want to reset the selected monitors?</h4>',
        title: '',
        showClass: {
          popup: ''
        },
        hideClass: {
          popup: ''
        },
        showCancelButton: true,
        confirmButtonColor: '#eb5757',
        cancelButtonColor: '#9ca3a1',
        confirmButtonText: 'Reset',
        cancelButtonText: 'Cancel'
      }).then(async (val) => {
        if (val.value === true) {
          this.tippyInstance.hide()
          try {
            await monitorsApi.multipleResetStats(this.selectedMonitors.map(item => item.id))
            this.$emit('reset')
            this.action = 'reset'
            this.showSuccessMessage()
          } catch (e) {
            this.showErrorMessage()
          }
        }
      })
    },

    showSuccessMessage () {
      swal.fire({
        title: 'Success',
        text: 'The monitors were successfully ' + this.action,
        showClass: {
          popup: ''
        },
        hideClass: {
          popup: ''
        },
        confirmButtonColor: '#6652e0',
        allowOutsideClick: false
      })
    },

    showErrorMessage () {
      swal.fire({
        title: 'Oops',
        text: 'Something went wrong ',
        showClass: {
          popup: ''
        },
        hideClass: {
          popup: ''
        },
        allowOutsideClick: true
      })
    },

    openTagsModal () {
      this.tippyInstance.hide()
      this.$modal.show('monitorTags', { monitors: this.selectedMonitors })
    }
  },

  computed: {
    ...mapState('authentication', [
      'user'
    ])
  }
}
</script>

<style lang="scss" scoped>
  .MonitorActions {
    width: 147px;

    &-toolbar {
      position: sticky;
      bottom: 0;
      background: rgba(245, 248, 249, 0.9);
      backdrop-filter: blur(3px);
      padding: 15px 0;
    }

    .button {
      width: 100%;

      ::v-deep {
        svg {
          color: white;
          order: 1;
          margin-right: 0 !important;
          margin-left: 0.5rem;
        }
      }
    }

    .nav {
      width: 127px;

      .nav-item {
        display: block;
        padding: 8px 10px;
        color: map-get($colors, gray-1);
        font-size: 15px;
        text-decoration: none;
        font-weight: 500;
        border-radius: 4px;

        &:hover {
          background-color: map-get($colors, purple-1);
          color: #fff;
        }
      }

      .spacer {
        margin: 10px 8px;
        border-bottom: 1px solid rgba(0,8,16,.15);
      }
    }

    .subscription-expired {
      padding: 4px 8px 8px 8px;
      font-size: 12px;
      color: #777;
    }
  }
</style>
